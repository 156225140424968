@import '../../colors';

// Setup color classes
$colors: ('red': $red, 'blue': $blue, 'green': $green, 'yellow': $yellow,
  'purple': $purple, 'turquoise': $turquoise, 'white': $white);

@each $name, $color in $colors {
  .counter.#{$name} {
    border-color: shade-color($color, 30%);
  }
  .counter.#{$name}:hover {
    border-color: shade-color($color, 10%);
  }

  .counter.#{$name} .label {
    background: linear-gradient(shade-color($color, 15%), shade-color($color, 15%)) 0 90%/0 2px no-repeat;
  }

  .radio-#{$name} input {
    background-color: $color;
    transition: 0.2s ease-out;
  }
  .radio-#{$name} input:hover {
    border-color: $color;
    box-shadow: 0px 0px 0px 1px $color;
  }
  .radio-#{$name} input:checked {
    background-color: $color;
    border-color: tint-color($background, 25%);
    box-shadow: 0px 0px 0px 3px $color;
  }
}

.no-counters-parent {
  display: flex;
  height: 70vh;
  align-items: center;
  width: fit-content;
}
.no-counters-parent .items {
  flex-direction: column;
  align-items: center;
}
.no-counters-parent .icon {
  font-size: 6rem;
  margin-bottom: 20px;
}

.counter-parent {
  padding-top: 20px;
}

.counter {
  background-color: tint-color($background, 8%);
  border: 2px solid shade-color($text, 50%);
  border-radius: 5px;
  margin-bottom: 15px;
  transition: border-color 0.3s;
}

.counter:hover {
  border-color: shade-color($text, 40%);
}

.counter .label {
  font-weight: 400;
  margin: 0;
  margin-left: 15px;
  user-select: none;
  width: fit-content;
  transition: .2s ease-out;
}
.counter .label:hover {
  text-shadow: 0 0 1px tint-color($text, 25%);
  background-size: 100% 2px;
  cursor: pointer;
}

.counter .amount {
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 3.4rem;
  margin: 0;
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.1rem;
}

.counter .increase {
  font-size: 3rem;
  font-weight: 400;
  user-select: none;
  margin: 5px;
  margin-top: 3px;
  border-radius: 3px;
  transition: 0.3s;
}
.counter .increase:hover {
  background-color: tint-color($background, 20%);
  cursor: pointer;
}

.form-text {
  color: shade-color($text, 20%);
}

.form-check-input:hover {
  cursor: pointer;
}
.form-check-input:checked[type=radio] {
  background-image: none;
}
