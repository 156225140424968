@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;400&display=swap');
@import 'colors';
@import '~bootstrap/scss/bootstrap';

$body-bg: $background;
$body-color: $text;

// Navbar
$navbar-dark-brand-color: $text;
$navbar-light-brand-hover-color: $text;

// Modal
$modal-content-bg: $background;
$modal-content-border-color: tint-color($background, 10%);
$modal-header-border-color: tint-color($background, 10%);
$modal-footer-border-color: tint-color($background, 10%);

// Button
$btn-font-weight: 400;

// Input
$input-color: $text;
$input-bg: tint-color($background, 30%);
$input-border-color: $input-bg;
$input-focus-bg: $input-bg;
$input-focus-color: $input-color;
$input-focus-border-color: $input-bg;

// Radio button
$form-check-radio-border-radius: 5px;

// Card
$card-bg: shade-color($background, 5%);

// Container
$container-max-widths: (
  lg: 760px
);

// Fonts
$font-weight-base: 200;
$main-font: 'Work Sans';
$font-family-sans-serif: $main-font, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
